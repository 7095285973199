
import {
  Component,
  Emit,
  Mixins,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { SimpleDialog, Table } from '@/components';
import { OrdersSharedType } from '@/modules/orders/shared/types';
import OrderSharedLink from '@/modules/orders/shared/components/OrderSharedLink.vue';
import { ErrorHandlereMixin } from '@/mixins';
import { client_orders_table_header } from '../../constans';
import { ClientsService } from '../../services';

const clientsService = new ClientsService();

@Component({
  components: {
    SimpleDialog,
    Table,
    OrderSharedLink,
  },
})
export default class ClientOrdersDialog extends Mixins(ErrorHandlereMixin) {
  @Prop()
  client_id!: number;

  table_headers = client_orders_table_header;

  loading = false;

  orders: OrdersSharedType[] = [];

  get show() {
    return this.$attrs.value;
  }

  @Emit()
  close() {
    return false;
  }

  @Watch('show')
  async on_show_handler() {
    if (this.show) {
      try {
        this.loading = true;
        const data = await clientsService.get_client_orders(this.client_id);

        this.orders = data;
        this.loading = false;
      } catch (error) {
        this.handleServerError(error);
      }
      return;
    }

    this.orders = [];
  }
}
