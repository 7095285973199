
import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { PolicyMixin } from '@/mixins';
import { Worktables } from '../types';

@Component
export default class WorktablesList extends Mixins(PolicyMixin) {
  @Prop({ type: Array })
  items!: Worktables.Worktable[];

  select_worktable(worktable: Worktables.Worktable) {
    return {
      name: Worktables.Pages.WORKTABLE_DETAIL,
      params: {
        id: String(worktable.id),
      },
    };
  }
}
