<template>
  <div>
    <TextField
      v-model="search"
      width="300px"
      rounded
      dense
      clearable
      autofocus
      append-icon="mdi-magnify"
    />
    <div
      v-if="this.clients.length > 0"
      id="searchable"
    >
      Результаты поиска:
      <div
        v-for="client in clients"
        :key="client.id"
      >
        <ClientsSearchListItem
          :item="client"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Component,
  Mixins,
  Watch,
} from 'vue-property-decorator';
import { ErrorHandlereMixin } from '@/mixins';
import { clients_store } from '@/store';
import TextField from '@/components/global/inputs/text-field/TextField.vue';
import ClientsSearchListItem from './ClientsSearchListItem.vue';

@Component({
  components: {
    ClientsSearchListItem,
    TextField,
  },
})
export default class ClientsSearch extends Mixins(ErrorHandlereMixin) {
  search = '';
  is_loading = false;

  mounted() {
    this.search = '';
  }

  @Watch('search')
  async on_input() {
    if (!this.search) {
      return;
    }

    if (this.is_loading) {
      return;
    }
    try {
      this.is_loading = true;
      await clients_store.actions.search_clients(this.search).finally(() => this.is_loading = false);
    } catch (error) {
      this.handleServerError(error);
    }
    this.is_loading = false;
  }

  get clients() {
    return clients_store.state.clients;
  }

  clear_search() {
    this.search = '';
    clients_store.actions.clear_search();
  }

  close() {
    return this.clear_search();
  }
}
</script>

<style module lang="scss">
</style>
