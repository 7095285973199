
/**
 * Todo - переписать компонент полностью
 * Сейчас есть баги в работе
 * 1. Избавиться от deep_copy
 * 2. Передавать в InstructorsSelect значения для редактирования
 */
import {
  Component,
  Watch,
  Prop,
  Mixins,
} from 'vue-property-decorator';
import { instructors_store } from '@/store';
import { ErrorHandlereMixin } from '@/mixins';
import { Instructor } from '../../types';

@Component({
  components: {},
})
export default class InstructorsSelect extends Mixins(ErrorHandlereMixin) {
  @Prop({ type: Array, default: () => ([]) })
  instructors!: Instructor[];

  @Watch('search')
  on_model_change() {
    if (this.search) {
      this.on_search_handler(this.search.trim());
    }
  }

  @Watch('instructors')
  on_instructors_change() {
    this.model = [...this.instructors];
  }

  search = '';
  model: Instructor[] = [...this.instructors];

  get items() {
    return instructors_store.state.instructors;
  }

  set items(val) {
    this.items = val;
  }

  async on_change_handler(entry = []) {
    try {
      const item = entry.find((el) => typeof el === 'string');

      if (item && !this.items.length) {
        const data = await instructors_store.actions.add_instructor({
          name: String(item),
        });
        const index = this.model.indexOf(item, 0);

        if (index > -1) {
          this.model.splice(index, 1);
          this.model.push(data as Instructor);
        }
      }
      this.$emit('change', this.model.filter((el) => el.id));
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async on_search_handler(val: string) {
    try {
      await instructors_store.actions.get_instructors({ search: val });
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
