
import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { PolicyMixin } from '@/mixins';
import ClientSharedLink from '@/modules/clients/shared/components/ClientSharedLink.vue';
import { TicketsStatuses } from '@/enums/statuses';
import TicketsUpdateClientForm from './TicketsUpdateClientForm.vue';
import { Tickets } from '../../types';

@Component({
  components: {
    TicketsUpdateClientForm,
    ClientSharedLink,
  },
})
export default class TicketsClientField extends Mixins(PolicyMixin) {
  @Prop({ type: Object })
  ticket!: Tickets.Ticket;

  dialog = false;

  get can_change_client() {
    return [
      TicketsStatuses.ACTIVE,
      TicketsStatuses.BOOKING,
    ].includes(this.ticket.status)
    && this.check_permissions(this.permissions.Orders.MODIFY);
  }

  close() {
    this.dialog = false;
  }
}
