
import {
  Component, Prop, Mixins,
} from 'vue-property-decorator';
import { Select, DatepickerMenuComponent } from '@/components';
import { AttractionsService } from '@/modules/attractions/services';
import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import { tickets_store, global_store } from '@/store';
import { ErrorHandlereMixin } from '@/mixins';
import { TicketsStatuses } from '@/enums';
import { Timeslots } from '@/modules/timeslots/types/index';
import moment from 'moment';
import { Tickets } from '../../types';

const attractionsService = new AttractionsService();

@Component({
  components: {
    Select,
    DatepickerMenuComponent,
  },
})
export default class TicketsSharedDateSelect extends Mixins(ErrorHandlereMixin) {
  today: string = moment(new Date()).format('YYYY-MM-DD');
  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop()
  ticket!: Tickets.Ticket;

  timeslots: TimeslotSharedType[] = [] as TimeslotSharedType[];

  tickets_statuses = TicketsStatuses;
  get momentDate() {
    return global_store.state.date;
  }

  get time() {
    return global_store.state.time;
  }

  get booking_date() {
    return this.ticket.timeslot?.booking_date;
  }

  get date() {
    return this.booking_date
      ? (new Date(Date.parse(this.booking_date))).toISOString().substr(0, 10)
      : null;
  }

  get is_active_status() {
    if (!this.ticket) {
      return true;
    }

    return ![
      this.tickets_statuses.BOOKING, this.tickets_statuses.ACTIVE,
    ].includes(this.ticket.status);
  }

  get is_disabled_date() {
    return (this.is_active_status || this.ticket.status === TicketsStatuses.COMPLETED);
  }

  get is_disabled_timeslot() {
    return !this.timeslots.length || this.ticket.status !== TicketsStatuses.COMPLETED;
  }

  get timeslot() {
    return this.ticket?.timeslot;
  }

  is_time_has_passed(item: Timeslots.Timeslot) {
    if (this.momentDate < this.today) {
      return true;
    }

    return this.momentDate === this.today && item.start_time < this.time;
  }

  row_bg_color(item: Timeslots.Timeslot) {
    return this.is_time_has_passed(item) ? 'grey lighten-2' : '';
  }

  update_timeslot(timeslot: TimeslotSharedType) {
    if (this.ticket.id) {
      // eslint-disable-next-line no-param-reassign
      timeslot.booking_date = this.booking_date;
      this.ticket.timeslot = timeslot;
      this.ticket.timeslot_id = timeslot.id;
      tickets_store.mutations.UPDATE_TIMESLOT_IN_TICKET({ ticket_id: this.ticket.id, timeslot });
    }
  }

  async mounted() {
    const date = this.booking_date || new Date().toLocaleString();

    this.update_timeslots(date);
  }

  async update_timeslots(date: string) {
    try {
      const timeslots = await attractionsService.get_attraction_timeslots({
        id: this.ticket.attraction_id,
        date,
      });

      this.timeslots = timeslots;
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
