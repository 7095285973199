
import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  Checkbox,
  Select,
} from '@/components';
import { client_statuses } from '@/enums';
import { clients_store } from '@/store';
import { ErrorHandlereMixin } from '@/mixins';
import { Clients, ClientsDifferences } from '../../types';
import ClientOrdersDialog from '../dialogs/ClientOrdersDialog.vue';
import UsersIsMatchDialog from '../dialogs/UsersIsMatchDialog.vue';
import { required_fields_for_comparison } from '../../constans';
import { ClientsService } from '../../services';

const { state, actions, mutations } = clients_store;
const clientsService = new ClientsService();

@Component({
  components: {
    Checkbox,
    Select,
    ClientOrdersDialog,
    UsersIsMatchDialog,
  },
})
export default class ClientCardHeaderActions extends Mixins(ErrorHandlereMixin) {
  @Prop()
  client!: Clients.Client;

  is_orders_dialog = false;
  clients_does_not_matches = false;
  differences: ClientsDifferences = 'email';
  select_items = client_statuses;

  get is_disabled_toe_tag_btn() {
    return this.client.application !== null;
  }

  get toe_tag_client() {
    return state.selected_toe_tag_client;
  }

  status_changed<T>(value: T, key: string) {
    if (key) {
      this.$emit('status_changed', { status_key: key, status_value: value });
    }
  }

  async connect_client_with_toetag() {
    if (!this.toe_tag_client) {
      return;
    }

    if (this.variance_required_fields) {
      this.differences = 'personal_data';
      this.clients_does_not_matches = true;
      return;
    }

    if (this.email_differences) {
      const clients = this.toe_tag_client.matches;

      if (clients.length) {
        const client_with_email_match = clients
          .find((client: Clients.Client) => client.email === this.toe_tag_client?.email);

        if (client_with_email_match?.id !== this.client.id) {
          this.differences = 'email';
          this.clients_does_not_matches = true;
          return;
        }
      }
    }

    this.link_toetag_with_client();
  }

  get variance_required_fields() {
    if (!this.toe_tag_client) {
      return true;
    }
    const manifest_client = this.client;

    return (Object.keys(manifest_client) as (keyof typeof manifest_client)[])
      .some((key) =>
        required_fields_for_comparison.includes(key)
          && manifest_client[key]
          && this.toe_tag_client[key]
          && manifest_client[key] !== this.toe_tag_client[key]);
  }

  get email_differences() {
    return this.client.email !== this.toe_tag_client?.email;
  }

  async link_toetag_with_client() {
    if (!this.toe_tag_client?.id || !this.client.id) {
      return;
    }

    try {
      const data = await clientsService.link_toetag_with_client(this.toe_tag_client.id, this.client.id);

      mutations.SELECT_CLIENT(data);
      mutations.REMOVE_CURRENT_TOE_TAG_CLIENT();
      await actions.get_toe_tag_clients();
      this.show_success(`Данные клиента  «${this.client.surname} ${this.client.firstname} ${this.client.middlename}» обновлены.`);
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
