
import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { ErrorHandlereMixin, PolicyMixin } from '@/mixins';
import { clients_store, tickets_store } from '@/store';
import { Tickets } from '../../types';
import ClientsSearchListItem from './ClientsSearchListItem.vue';
import { TicketsService } from '../../services';
import ClientsSearch from './ClientsSearch.vue';

const ticketsService = new TicketsService();

@Component({
  components: {
    ClientsSearch,
    ClientsSearchListItem,
  },
})
export default class TicketsUpdateClientForm extends Mixins(PolicyMixin, ErrorHandlereMixin) {
  @Prop({ type: Object })
  ticket!: Tickets.Ticket;

  @Prop({ type: Boolean, default: false })
  dialog!: boolean;

  model = '';

  get show() {
    return this.dialog;
  }

  set show(value) {
    if (!value) {
      this.close();
    }
  }

  get items() {
    return clients_store.state.clients;
  }

  get disabled() {
    return !clients_store.state.selected_ticket_client;
  }

  close() {
    this.clear_search();
    this.$emit('close');
  }

  clear_search() {
    clients_store.actions.clear_search();
  }

  async save() {
    const client = clients_store.state.selected_ticket_client;

    if (this.disabled) {
      return;
    }

    try {
      this.ticket.client = client;
      const data = await ticketsService.update(this.ticket);

      tickets_store.dispatch('update_ticket', data);
      this.close();
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
