
import {
  Component,
  Mixins,
  Prop,
  Ref,
} from 'vue-property-decorator';
import { ValidateMixin } from '@/mixins';
import { TextField } from '@/components';
import { VForm } from '@/types';

@Component({
  components: {
    TextField,
  },
})
export default class JumpsForm extends Mixins(ValidateMixin) {
  @Ref()
  private readonly form!: VForm;

  @Prop()
  bungy207_count!: number;

  @Prop()
  bungy69_count!: number;

  @Prop()
  sochi_swing_count!: number;

  delay_input: ReturnType<typeof setTimeout>|null = null;

  update_jumps<T>(type: T, value: number) {
    if (this.delay_input) {
      clearTimeout(this.delay_input);
    }

    this.delay_input = setTimeout(() => {
      this.$emit('update_jumps', { type, value });
    }, 300);
  }

  form_valid = false;
}
