
import { ResponseStatuses, TicketsStatuses, TicketsActions } from '@/enums';
import { global_store, tickets_store, timeslots_store } from '@/store';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { MessagesMixin, ErrorHandlereMixin } from '@/mixins';
import ConfimCancelOrderDialog from '@/modules/timeslots/shared/components/ConfimCancelOrderDialog.vue';
import ConfimRemoveAssignDialog from '@/modules/timeslots/shared/components/ConfimRemoveAssignDialog.vue';
import { TimeslotsService } from '@/modules/timeslots/services';
import { permissions_by_ticket_actions } from '../../constans';
import { Tickets } from '../../types';

const timeslotsService = new TimeslotsService();

@Component({
  components: {
    ConfimCancelOrderDialog,
    ConfimRemoveAssignDialog,
  },
})
export default class TicketSharedActions extends Mixins(MessagesMixin, ErrorHandlereMixin) {
  @Prop()
  status!: TicketsStatuses;

  @Prop()
  ticket!: Tickets.Ticket;

  ticket_action = TicketsActions;
  cancel_orderd_dialog = false;
  remove_assign_dialog = false;

  check_permission(action: TicketsActions): boolean {
    return permissions_by_ticket_actions[action].includes(this.status);
  }

  async cancel(comment: string) {
    try {
      if (this.ticket.id) {
        await tickets_store.actions.cancel_ticket({
          ticket_id: this.ticket.id,
          comment,
        });
        this.cancel_orderd_dialog = false;
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async open_timslot() {
    const timeslot = await timeslotsService.get_timeslot(this.ticket.timeslot_id);

    timeslot.tickets = [this.ticket];
    const attraction_node = document.body.querySelector('#WorktableAttractionsSlider');

    if (attraction_node) {
      attraction_node.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }

    timeslots_store.mutations.SET_TIMESLOT_FOR_ORDER(timeslot);
  }

  async assign() {
    try {
      if (this.ticket.id) {
        const timeslot_id = tickets_store.getters.get_by_id(this.ticket.id)?.timeslot?.id;

        if (timeslot_id) {
          await tickets_store.actions.assign_ticket({
            ticket_id: this.ticket.id,
            timeslot_id,
          });
        } else {
          this.show_warning('Не выбран таймслот');
        }
      }
    } catch (error: any) {
      if (error.status === ResponseStatuses.Fail.BAD_REQUEST) {
        this.show_error('Таймслот не имеет свободных мест');
      } else {
        this.handleServerError(error);
      }
    }
  }

  async revert_cancel() {
    try {
      if (this.ticket.id) {
        await tickets_store.actions.revert_cancel(this.ticket.id);
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async remove_assign() {
    try {
      if (this.ticket.id) {
        this.remove_assign_dialog = false;
        await tickets_store.actions.remove_assign_of_ticket(this.ticket.id);
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async uncomplete() {
    try {
      if (this.ticket.id) {
        await tickets_store.actions.uncomplete_of_ticket(this.ticket.id);
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }

  show_error(message = 'Ошибка таймслота') {
    global_store.actions.show_snackbar({
      message,
      color: 'red',
      icon: 'mdi-alert-circle-outline',
    });
  }
}
