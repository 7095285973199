
import { TicketsStatuses, TicketsStatusesTranslate } from '@/enums';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TicketsSharedStatusChip extends Vue {
    @Prop()
    status!: TicketsStatuses;

    get ticket_status() {
      return TicketsStatusesTranslate[this.status];
    }
}
