
import { OrderTypes, OrderTypesTranslate } from '@/enums';
import { TicketsSharedType } from '@/modules/tickets/shared/types';
import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';
import TicketsSharedTable from '@/modules/tickets/shared/components/TicketsSharedTable.vue';

@Component({
  components: {
    TicketsSharedTable,
  },
})
export default class ClientTicketsTabs extends Vue {
  @Prop()
  tickets!: TicketsSharedType;

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  @Emit()
  select_tab(tabs_index: number) {
    return this.tabs[tabs_index].type;
  }

  tab = 0;

  tabs = [
    {
      type: OrderTypes.ONLINE,
      text: `${OrderTypesTranslate.online} билеты`,
    },
    {
      type: OrderTypes.OFFLINE,
      text: `${OrderTypesTranslate.offline} билеты`,
    },
  ];

  mounted() {
    this.select_tab(0);
  }

  tabs_handler() {
    this.select_tab(this.tab);
  }
}
