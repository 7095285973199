var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('SimpleDialog',_vm._g(_vm._b({attrs:{"max-width":"720"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Редактирование данных клиента ")]},proxy:true},{key:"text",fn:function(){return [_c('h4',{staticClass:"mb-3"},[_vm._v("ФИО клиента *")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"label":"Фамилия","require":"","hide-details":"","rules":[
              v => _vm.is_require(v) || _vm.require_message
            ]},model:{value:(_vm.client_model.surname),callback:function ($$v) {_vm.$set(_vm.client_model, "surname", $$v)},expression:"client_model.surname"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"label":"Имя","require":"","hide-details":"","rules":[
              v => _vm.is_require(v) || _vm.require_message
            ]},model:{value:(_vm.client_model.firstname),callback:function ($$v) {_vm.$set(_vm.client_model, "firstname", $$v)},expression:"client_model.firstname"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"label":"Отчество","hint":"Если есть","persistent-hint":""},model:{value:(_vm.client_model.middlename),callback:function ($$v) {_vm.$set(_vm.client_model, "middlename", $$v)},expression:"client_model.middlename"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('DatepickerComponent',{attrs:{"label":"Дата рождения","hide-details":"","clearable":""},model:{value:(_vm.client_model.birthday),callback:function ($$v) {_vm.$set(_vm.client_model, "birthday", $$v)},expression:"client_model.birthday"}})],1)],1),_c('h4',{staticClass:"mt-2 mb-3"},[_vm._v("Контактные данные")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"label":"Email","hide-details":"","rules":[
              v => _vm.is_email(v) || _vm.invalid_email,
            ]},model:{value:(_vm.client_model.email),callback:function ($$v) {_vm.$set(_vm.client_model, "email", $$v)},expression:"client_model.email"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask.PHONE),expression:"mask.PHONE"}],attrs:{"label":"Телефон","require":"","hide-details":"","type":"tel","masked":false,"rules":[
              v => _vm.is_require(v) || _vm.require_message,
              v => _vm.is_correct_phone(v) || _vm.invalid_phone_number,
            ]},model:{value:(_vm.client_model.phone),callback:function ($$v) {_vm.$set(_vm.client_model, "phone", $$v)},expression:"client_model.phone"}})],1)],1),_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("Дополнительные данные *")]),_c('v-row',{staticClass:"mb-3"},[_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"label":"Страна","hide-details":""},model:{value:(_vm.client_model.country),callback:function ($$v) {_vm.$set(_vm.client_model, "country", $$v)},expression:"client_model.country"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"label":"Город","hide-details":""},model:{value:(_vm.client_model.city),callback:function ($$v) {_vm.$set(_vm.client_model, "city", $$v)},expression:"client_model.city"}})],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"secondary","rounded":"","disabled":!_vm.form_valid},on:{"click":_vm.submit}},[_vm._v(" Сохранить изменения ")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")])]},proxy:true}])},'SimpleDialog',_vm.$attrs,false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }