
import { Component, Mixins } from 'vue-property-decorator';
import {
  BlockTitle,
  Table,
  Panels,
  PanelsItem,
  CommentDialog,
} from '@/components';
import TicketsSharedTable from '@/modules/tickets/shared/components/TicketsSharedTable.vue';
import { orders_store } from '@/store';
import { PolicyMixin, ErrorHandlereMixin } from '@/mixins';

const { state, actions } = orders_store;

@Component({
  components: {
    BlockTitle,
    Table,
    TicketsSharedTable,
    Panels,
    PanelsItem,
    CommentDialog,
  },
})
export default class OrderSharedCard extends Mixins(PolicyMixin, ErrorHandlereMixin) {
  panel_model = null;

  is_add_comment_dialog = false;

  comments_table_header = [
    {
      text: '',
      value: 'created_at',
    },
    {
      text: '',
      value: 'commentator',
    },
    {
      text: '',
      value: 'comment',
      width: '60%',
    },
  ];

  get order() {
    return state.selected_order;
  }

  get buyer() {
    return state.selected_order?.buyer;
  }

  get comments() {
    return state.selected_order?.comments;
  }

  async add_comment(comment: string) {
    try {
      if (this.order?.id) {
        actions.create_comment({
          id: this.order?.id,
          comment,
        });
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }

  close() {
    orders_store.actions.close_order();
  }
}
