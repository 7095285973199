
import { ClientsSharedType } from '@/modules/clients/shared/types';
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { clients_store } from '@/store';

@Component
export default class ClientsSearchListItem extends Vue {
  @Prop()
  item!: ClientsSharedType;

  get full_name() {
    return `${this.item.surname} ${this.item.firstname} ${this.item.middlename || ''}`.trim();
  }

  click_client_item(item: ClientsSharedType) {
    clients_store.mutations.SET_TICKET_CLIENT(item);
  }
}
