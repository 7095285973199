
import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { Table, CommentDialog, Checkbox } from '@/components';
import { OrderTypes } from '@/enums';
import OrderSharedLink from '@/modules/orders/shared/components/OrderSharedLink.vue';
import ClientSharedLink from '@/modules/clients/shared/components/ClientSharedLink.vue';
import { tickets_store, statistics_store } from '@/store';
import { PolicyMixin, ErrorHandlereMixin } from '@/mixins';
import { ClientsSharedType } from '@/modules/clients/shared/types';
import { Tickets } from '../../types';
import { tickets_client_table_headers, tickets_order_table_headers } from '../../constans';
import TicketsSharedDateSelect from './TicketsSharedDateSelect.vue';
import TicketsSharedStatusChip from './TicketSharedStatusChip.vue';
import TicketsSharedActions from './TicketSharedActions.vue';
import TicketsClientField from './TicketsClientField.vue';

const { state } = statistics_store;

const { actions } = tickets_store;

@Component({
  components: {
    TicketsClientField,
    Table,
    Checkbox,
    OrderSharedLink,
    CommentDialog,
    TicketsSharedDateSelect,
    TicketsSharedStatusChip,
    TicketsSharedActions,
    ClientSharedLink,
  },
})
export default class TicketsSharedTable extends Mixins(PolicyMixin, ErrorHandlereMixin) {
  @Prop({ type: Array, default: () => ([]) })
  items!: Tickets.Ticket[];

  get creators() {
    return state.history.creators;
  }

  @Prop({ type: Object })
  buyer!: ClientsSharedType;

  @Prop({ type: Boolean, default: false })
  is_order_card!: boolean;

  @Prop({ default: () => OrderTypes.ONLINE })
  type!: OrderTypes;

  comments_table_header = [
    {
      text: '',
      value: 'created_at',
    },
    {
      text: '',
      value: 'commentator.name',
    },
    {
      text: '',
      value: 'comment',
      width: '60%',
    },
  ];

  get headers() {
    let headers = this.is_order_card ? tickets_order_table_headers : tickets_client_table_headers;

    if (!this.check_permissions(this.permissions.Orders.MODIFY, this.items[0]?.booked_at)) {
      headers = headers.filter((header) => header.value !== 'actions');
    }

    return headers;
  }

  expanded = [];
  order_types = OrderTypes;
  is_comment_dialog = false;
  on_edit_ticket_id: number | null = null;

  get buyer_id() {
    return this.buyer?.id ?? null;
  }

  select_ticket_to_comment(ticket_id: number) {
    this.on_edit_ticket_id = ticket_id;
    this.is_comment_dialog = true;
  }

  async add_comment(comment: string) {
    try {
      if (this.on_edit_ticket_id) {
        actions.create_comment({
          id: this.on_edit_ticket_id,
          comment,
        });
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
