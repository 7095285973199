
import {
  Component,
  Emit,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { SimpleDialog } from '@/components';
import { ClientsDifferences } from '../../types';

@Component({
  components: {
    SimpleDialog,
  },
})
export default class UsersIsMatchDialog extends Vue {
  @Prop()
  differences!: ClientsDifferences

  @Emit()
  close() {
    return false;
  }

  @Emit()
  update_client() {
    this.close();
  }

  get title() {
    if (this.differences === 'email') {
      return 'Указанный e-mail уже используется';
    }

    return 'Расхождение персональных данных';
  }

  get text() {
    if (this.differences === 'email') {
      return 'Внимание! Email из Тое Таg принадлежит другому Клиенту!';
    }

    return 'Данные из Тое Таg не сходятся с выбранным Клиентом. Сначала исправьте данные в профиле Клиента или Тое Таg!';
  }
}
