
import {
  Component,
  Ref,
  Mixins,
} from 'vue-property-decorator';
import { ValidateMixin } from '@/mixins';
import { TextField } from '@/components';
import { VForm } from '@/types';
import InstructorsSelect from '@/modules/instructors/shared/components/InstructorsSelect.vue';
import { InstructorShared } from '@/modules/instructors/shared/types';

@Component({
  components: {
    TextField,
    InstructorsSelect,
  },
})
export default class AddWorktableDialog extends Mixins(ValidateMixin) {
  @Ref()
  readonly form!: VForm;

  worktable_name = '';
  instructors: InstructorShared[] = [];

  form_valid = false;

  submit() {
    if (this.form_valid) {
      this.$emit('submit', {
        title: this.worktable_name,
        instructors: this.instructors,
      });
    }
  }

  instructors_change(val: InstructorShared[]) {
    this.instructors = val;
  }
}
