
import { Component, Vue } from 'vue-property-decorator';
import { Statistics } from '@/modules/statistics/types';

@Component
export default class WorktableDetailOptions extends Vue {
  statistics_route() {
    return {
      name: Statistics.Pages.STATISTICS_DETAIL,
      params: {
        id: this.$route.params.id,
      },
    };
  }
}
