
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { clients_store } from '@/store';
import { ErrorHandlereMixin } from '@/mixins';
import { Clients } from '../../types';

@Component
export default class ClientSharedLink extends Mixins(ErrorHandlereMixin) {
  @Prop()
  client!: Clients.Client;

  loading = false;

  async open_client() {
    try {
      this.loading = true;

      if (this.client.id) {
        await clients_store.actions.set_client_by_id(this.client.id);
      }
      this.$emit('client_selected');
    } catch (error) {
      this.handleServerError(error);
    } finally {
      this.loading = false;
    }
  }
}
