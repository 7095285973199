
import {
  Mixins, Component, Emit, Ref, Prop,
} from 'vue-property-decorator';
import { SimpleDialog, TextField, DatepickerComponent } from '@/components';
import { ValidateMixin } from '@/mixins';
import { VForm } from '@/types';
import { ValidationMasks } from '@/enums';
import { clone_deep } from '@/utils';
import { Clients } from '../../types';

@Component({
  components: {
    SimpleDialog,
    TextField,
    DatepickerComponent,
  },
})
export default class EditClientDialog extends Mixins(ValidateMixin) {
  @Ref()
  private readonly form!: VForm;

  @Prop()
  client!: Clients.Client;

  form_valid = false;

  get client_model() {
    return clone_deep(this.client);
  }

  @Emit()
  close() {
    return false;
  }

  mask = ValidationMasks;

  submit() {
    if (this.form.validate()) {
      this.$emit('submit', this.client_model);
    }
  }

  inputHandler(val: string) {
    return Number(val.replace(/[^\d]/g, ''));
  }
}
